<template>
  <div class="tree_container">
    <el-tabs type="border-card">
      <el-tab-pane>
        <div class="tree_title" slot="label">
          <i class="el-icon-s-fold"></i>栏目列表
        </div>
        <!-- 树结构 -->
        <div class="tree_detail">
          <el-tree
            :data="data"
            :props="defaultProps"
            @node-click="handleNodeClick"
            highlight-current
            :current-node-key="currentKey"
            node-key="row_id"
            :default-expanded-keys="defaultExpandKey"
            :expand-on-click-node="false"
            ref="tree"
            auto-expand-parent
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ data.label }}</span>
              <span class="editName">
                <el-input
                  size="mini"
                  :ref="'input' + data.row_id"
                  v-model="newLabel"
                  @keyup.enter.native="$event.target.blur()"
                  @blur="inputBlur(node, data)"
                  v-show="data.isInput"
                  class="inputClass"
                  @click.stop.native="() => {}"
                ></el-input>
              </span>

              <span class="operation">
                <el-tooltip content="添加" placement="top" effect="dark">
                  <i
                    v-if="node.level == 1 || node.level == 2 || node.level == 3"
                    class="el-icon-plus"
                    style="color: rgb(73, 154, 254); font-weight: 300"
                    @click.stop="() => append(node, data)"
                    :ref="'add' + data.row_id"
                  >
                  </i>
                </el-tooltip>
                <!-- v-if="data.type != '' && node.level == 4" -->
                <el-tooltip content="修改" placement="top" effect="dark">
                  <i
                    v-if="node.level == 2 || node.level == 3 || node.level == 4"
                    class="el-icon-edit"
                    style="color: rgb(73, 154, 254); font-weight: 300"
                    @click.stop="edit(node, data)"
                    :ref="'edit' + data.row_id"
                  ></i>
                </el-tooltip>
                <!-- v-if="data.type != '' && node.level == 4" -->
                <el-tooltip content="删除" placement="top" effect="dark">
                  <i
                    v-if="node.level == 2 || node.level == 3 || node.level == 4"
                    class="el-icon-delete"
                    style="color: rgb(73, 154, 254)"
                    @click.stop="() => remove(node, data)"
                    :ref="'del' + data.row_id"
                  >
                  </i>
                </el-tooltip>
              </span>
            </span>
          </el-tree>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// let id = 1000;
// import { data } from "./conf/dataTree";

import { filterParams } from "@common/publicmethods.js";
export default {
  data() {
    return {
      newLabel: "", //保存新修改的栏目名称
      display: "none",
      dialogFormVisible: false,
      formLabelWidth: "150px",
      childNodeForm: {
        // 保存新节点数据
        // id: id++,
        // label: "新增",
        // isAdd: false,
        // isDel: true,
        // isEdit: true,
        // isShowInput: false,
      },
      nodeData: {}, // 存放节点数据
      data: [
        {
          label: "资源目录",
          children: [],
          row_id: 0,
        },
      ], //存放全部的栏目数据
      defaultProps: {
        children: "children",
        label: "label",
      },
      rules: {
        id: [
          { required: true, message: "请输入栏目ID", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
          { type: "string", message: "必须为数字值", trigger: "blur" },
        ],
      },
      currentKey: null, // 保存当前的选中的node节点的key
      addUserd: 0,
      currentNode: null, // 保存添加时的node节点
      defaultExpandKey: [0], // 默认展开的节点的row_id
      loading: true,
      is_resource: false, // 是否关联数据
      is_show_image: false, //是否显示image
      identifyNum: 1, //保证每次新增的identify都不一样
      //如果返回的tree为空 用emptyData代替
      emptyData: [
        {
          children: [],
          label: "欢迎页",
        },
        {
          children: [],
          label: "首页",
        },
        {
          children: [],
          label: "在线预定",
        },
        {
          children: [],
          label: "互动服务",
        },
        {
          children: [],
          label: "我的",
        },
      ],
    };
  },
  created() {
    this.$bus.$off("saveSuccess", (id) => {
      this.saveSuccessAndClickTree(id);
    });
  },
  beforeDestroy() {
    this.$bus.$off("saveSuccess", (id) => {
      this.saveSuccessAndClickTree(id);
    });
  },
  mounted() {
    //console.log("tree",this.$refs.tree);
    this.queryColumnTree(1);
    //this.currentKey = "1"

    //this.defaultChecked("1");

    // 监听保存成功后的事件处理
    this.$bus.$on("saveSuccess", (id) => {
      this.saveSuccessAndClickTree(id);
    });
  },

  methods: {
    // 默认展开的节点
    // defaultExpandKey(){
    //   return [1,2,3,4]
    // },
    // 左侧节点 点击事件处理
    handleNodeClick(data, node) {
      console.log("你好data", data);
      this.currentKey = data.row_id; // 设置当前节点的key
      let level = node.level; // 获取节点的层级

      //let is_resources = await queryDataOfFuncModules(data.resources);
      this.queryDataOfFuncModules(data.func); // 查询资源是否关联

      // 不是三级节点不需要判断
      if (level == 3) {
        //this.currentNodeIsShowImage(node); // 判断当前节点是否需要显示image
        this.is_show_image = true;
      } else {
        this.is_show_image = false;
      }

      data.is_show = data.is_show == "1" ? true : false;
      data.is_guide = data.is_guide == "1" ? true : false;
      data.hide_menu = data.hide_menu == "1" ? true : false;
      this.$set(data, "level", level);
      this.$set(data, "is_show_img", this.is_show_image);
      this.$emit("nodeClick", data); //
      this.$store.commit("setModuleState", data.func);
      console.log("点击右侧节点", data);
    },

    // 点击 新增 事件
    // node为当前添加的对象，data为数据
    append(node, data) {
      // this.currentNode = node;
      //this.$store.commit("setIsEdit", true);
      node.expand(); // 点击添加时先展开该节点！！

      //this.dialogFormVisible = true;
      //this.nodeData = data;
      this.addUserd = this.uuid();
      const childForm = {
        row_id: this.addUserd,
        parent_id: data.row_id,
        label: "新增add",
        name: "新增",
        identify: `newAdd${this.identifyNum++}`,
        title: "",
        style: "basicStyle",
        type: "1",
        func: "",
        //image: "0",
        //logo1: "",
        //logo2: "",
        is_show: true,
        is_guide: false,
        hide_menu: false,
        is_end: "0",
        isInput: false,
        //children: []
      };
      if (!data.children) {
        this.$set(data, "children", []);
      }
      // 判断添加的节点是第几层级
      if (node.level == 1) {
        childForm.type = "0";
        // childForm.isDel = true;
        //childForm.name = "新增二级节点";
      } //else if (node.level == 2) {
      //childForm.name = "新增三级节点";
      //}
      // 添加子节点
      data.children.push(childForm);

      // 新增后聚焦并选中标题
      childForm.isInput = true; //必须先显示才能聚焦,isInput用来判断input输入框的显示于隐藏的
      setTimeout(() => {
        this.selectAndFocus(childForm);
      }, 100);

      this.newLabel = childForm.label; // 把原来得名称复制给input，同时清空原来的label
      childForm.label = "";

      this.currentKey = this.addUserd;
      // let childParams = data.children[data.children.length-1];
      // childParams.is_show_img = true
      // childParams.level = 3
      // this.$emit("nodeClick", childParams);
      //let formc = this.clearSubmitData(childForm);

      // delete childForm.row_id
      let params = {
        data: filterParams(childForm),
        directory_code: this.$store.state.currentNav.index,
      };
      console.log(params);
      this.$Request({
        method: "post",
        url: this.$Api.addDataOfResource,
        headers: { "Content-Type": "application/json" },
        data: params,
      })
        .then((res) => {
          console.log(res);
          // this.$message({
          //   type: "success",
          //   message: "添加成功!",
          // });
          let params = {
            data: { identify: childForm.identify },
            directory_code: this.$store.state.currentNav.index,
          };
          this.$Request({
            method: "get",
            url: this.$Api.queryDataOfResource,
            params,
          })
            .then((res) => {
              console.log("新增：", res);
              if (res.errcode == 0) {
              }
            })
            .catch();
        })
        .catch((err) => {});
    },

    // 点击删除事件
    remove(node, data) {
      let msg = `确定删除栏目 <span style="color:red;font-weight:bold">${data.label}</span> 吗, 请谨慎考虑!`;
      this.$confirm(msg, "栏目删除操作", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          const parent = node.parent;
          // console.log('parent',parent)
          const children = parent.data.children || parent.data;
          const index = children.findIndex((d) => d.row_id === data.row_id);
          children.splice(index, 1);
          let params = {
            data: { row_id: data.row_id },
            directory_code: this.$store.state.currentNav.index,
          };
          this.$Request({
            method: "get",
            url: this.$Api.deleteDataOfResource,
            headers: { "Content-Type": "application/json" },
            params,
          })
            .then((res) => {
              // console.log("del", res);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              //this.queryColumnTree(9);
              this.currentKey = 1;
            })
            .catch((err) => {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 点击 编辑 事件
    edit(node, data) {
      // console.log(data)
      data.isInput = true; //必须先显示才能聚焦！！
      // this.selectAndFocus('我点击了编辑的事件',data);
      this.selectAndFocus(data);
      // this.$nextTick(() => {
      //    this.$refs.input.focus();
      // });
      this.newLabel = data.label; // newLabel是input的value值,
      data.label = "";
    },
    // input失去焦点 修改栏目名字
    inputBlur(node, data) {
      if (this.newLabel == "") {
        this.$message({
          type: "error",
          message: "名称不能为空!",
        });
        this.selectAndFocus(data);
      } else {
        data.isInput = false;
        //this.currentKey = 0;
        this.currentKey = this.addUserd;
        // 修改名字的接口
        data.label = this.newLabel;
        data.name = this.newLabel;
        //let formc = this.clearSubmitData(data)
        let params = {
          data: {
            row_id: data.row_id,
            parent_id: data.parent_id,
            label: this.newLabel,
            name: this.newLabel,
          },
          directory_code: this.$store.state.currentNav.index,
        };

        this.$Request({
          method: "post",
          url: this.$Api.modifyDataOfResource,
          // headers: {'Content-Type': 'application/json'},
          data: params,
        })
          .then((res) => {
            console.log(res);
            if (res.errCode == 0) {
              this.$message({
                type: "success",
                message: "栏目名称修改成功!",
              });

              if (typeof data.row_id == "number") {
                this.currentKey = 0;
                this.queryColumnTree(data.row_id, data.parent_id);
              }
              //console.log(isNaN(data.row_id));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    // 选中和聚焦
    selectAndFocus(data) {
      this.$nextTick(() => {
        let inputObj = this.$refs[`input${data.row_id}`];
        // console.log("input:", inputObj);
        inputObj.focus();
        inputObj.select(); //选中input的文字
      });
    },
    //处理上传前的表单  int类型 为空都写为0 删除create_time
    clearSubmitData(fromData) {
      delete fromData.create_time;
      //删除空字段
      Object.keys(fromData).map((item) => {
        // let isint = false;
        // if (this.fieldMap.hasOwnProperty(item)) {
        //   isint = this.fieldMap[item].indexOf("int") != -1;
        // }
        if (!fromData[item]) {
          delete fromData[item];
        }
        return true;
      });
      return fromData;
    },

    // 查询树节点
    queryColumnTree(id, pid = 0) {
      this.$Request({
        method: "get",
        url: this.$Api.queryColumnTree,
      }).then((res) => {
        if (res.data == null) {
          // this.data = this.emptyData;
        } else {
          // console.log("数据树资源：父亲", res.data[1].children);
          // console.log("数据树资源：孩子", res);
          // 顺序变动处理
          if (res.data[1] && res.data[1].children) {
            res.data[1].children.forEach((item) => {
              // 原先97等于导航栏2,
              // if(item.row_id == 97){
              if (item.row_id == 51) {
                // let index = res.data[0].children.indexOf(item)
                let index = res.data[1].children.indexOf(item);
                let ob = item;
                res.data[1].children.splice(index, 1);
                res.data[1].children.splice(1, 0, ob);
                // 原先104等于广告栏
                // }else if(item.row_id == 104){
              } else if (item.row_id == 56) {
                let index = res.data[1].children.indexOf(item);
                let ob = item;
                res.data[1].children.splice(index, 1);
                res.data[1].children.splice(4, 0, ob);
              }
            });
          }

          this.data[0].children = res.data;
        }
        console.log("我是树节点", res.data);
        //this.currentKey = 0;
        this.currentKey = id;
        // this.loading = false;
        if (pid != 0) {
          this.$nextTick(() => {
            this.defaultExpandKey.push(pid);
          });
        }
        // console.log("当前节点", this.currentNode);
        // if (this.currentNode != null) {
        //   setTimeout(() => {
        //     console.log("展开");
        //     this.currentNode.expand();
        //   }, 2000);
        // }
      });
    },

    uuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("");
      return uuid;
    },
    // 查询功能模块是否关联数据资源
    queryDataOfFuncModules(resources) {
      if (resources != "") {
        let params = {
          data: {
            // is_check: 1,
          },
          directory_code: "amt_module",
        };
        this.$Request({
          method: "get",
          url: this.$Api.queryDataOfResource,
          params,
        })
          .then((res) => {
            if (res.errcode == 0) {
              console.log("功能模块", res.data);
              let list = res.data;
              list.forEach((item) => {
                if (item.identify == resources) {
                  if (item.is_resources == "1") {
                    this.$store.commit("setIsResource", true);
                    //this.is_resource = true;
                  } else {
                    //this.is_resource = false;
                    this.$store.commit("setIsResource", false);
                  }
                }
              });
            }
          })
          .catch((err) => {});
      } else {
        this.$store.commit("setIsResource", false);
      }
    },
    // 保存数据后点击对应的树节点
    saveSuccessAndClickTree(id) {
      this.$nextTick(() => {
        this.addUserd = id;
        this.currentKey = 0;
        this.queryColumnTree(id);
        this.defaultExpandKey.push(id);
        //document.querySelector(".is-current").firstChild.click();
      });
    },

    // 判断当前节点需不需要展示image
    currentNodeIsShowImage(node) {
      if (
        node.parent.parent.data.row_id == 9 ||
        node.parent.parent.data.row_id == 11 ||
        node.parent.parent.data.row_id == 13
      ) {
        this.is_show_image = true;
      } else {
        this.is_show_image = false;
      }
    },
  },
  watch: {
    //监听当前节点的变化！！并且选中它！！！
    currentKey(e) {
      //debugger;
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(e);

        // if (this.currentNode != null) {
        //   setTimeout(() => {
        //     console.log("展开");
        //     this.currentNode.expand();
        //   }, 5000);
        // }
        if (e != 0) {
          this.$nextTick(() => {
            document.querySelector(".is-current").firstChild.click();
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tree_container {
  font-size: 14px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0px #dcdfe6;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  height: 100% !important;
  // overflow-y: scroll;
}

@media (max-width: 1280px) {
  .tree_container {
    font-size: 12px;
  }
}

.tree_title {
  color: #499afe;
  font-size: 14px;
  // padding: 0 20px;
  height: 40px;
  line-height: 40px;
  i {
    margin-right: 5px;
  }
}
.el-tabs__item,
.is-top,
.is-active {
  padding: 0 !important;
}
.tree_detail {
  padding: 15px;
  //padding-left: 10px;
  height: calc(100vh - 150px);
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fff;
}
.operation {
  // float: right;
  font-size: 14px;
  position: absolute;
  right: 0;
  z-index: 99;
}
.operation i {
  margin-right: 4px;
}
// .el-tabs__item,.is-top,.is-active{
//   background: #f5f7fa!important;
// }
.inputClass {
  //position: relative;
  left: -15px;
  top: 0;
  //z-index: 2;
  width: auto;
  height: 25px;
}
/deep/.el-input__inner {
  height: 24px;
  line-height: 24px;
}
.editName {
  display: inline-block;
  // position: relative;
}
/deep/.el-tabs--border-card > .el-tabs__content {
  padding: 0 !important;
}
/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 3px;
  //padding-left: 10px;
}
//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 3px;
}
</style>
